var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        { staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" },
        [
          _c(
            "q-form",
            { ref: "editForm" },
            [
              _vm.popupParam.psiPowerEquipmentId
                ? _c(
                    "c-card",
                    {
                      staticClass: "cardClassDetailForm revisionLayout",
                      attrs: { title: "개정", topClass: "topcolor-orange" },
                    },
                    [
                      _c("template", { slot: "card-detail" }, [
                        _c(
                          "div",
                          {
                            staticClass:
                              "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12",
                          },
                          [
                            _c("div", { staticClass: "row" }, [
                              _c(
                                "div",
                                { staticClass: "col-1" },
                                [
                                  _c("c-text", {
                                    attrs: {
                                      required: _vm.editable && _vm.isRevision,
                                      editable: _vm.editable && _vm.isRevision,
                                      label: "개정번호",
                                      name: "revisionNum",
                                    },
                                    model: {
                                      value: _vm.data.revisionNum,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.data, "revisionNum", $$v)
                                      },
                                      expression: "data.revisionNum",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "col-2" },
                                [
                                  _c("c-text", {
                                    attrs: {
                                      editable: false,
                                      label: "개정일시",
                                      name: "regDtStr",
                                    },
                                    model: {
                                      value: _vm.data.regDtStr,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.data, "regDtStr", $$v)
                                      },
                                      expression: "data.regDtStr",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "col-1" },
                                [
                                  _c("c-text", {
                                    attrs: {
                                      editable: false,
                                      label: "개정자",
                                      name: "regUserName",
                                    },
                                    model: {
                                      value: _vm.data.regUserName,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.data, "regUserName", $$v)
                                      },
                                      expression: "data.regUserName",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "col-4" },
                                [
                                  _c("c-moc", {
                                    attrs: {
                                      editable: _vm.editable && _vm.isRevision,
                                      isSubmit: _vm.saveCallData,
                                      document: _vm.data,
                                      documentId:
                                        _vm.popupParam.psiPowerEquipmentId,
                                      documentTitle: "powerMachineName",
                                      mocRelatedTaskCd: "RT00000001",
                                      label: "MOC번호",
                                      name: "sopMocId",
                                    },
                                    on: {
                                      "update:document": function ($event) {
                                        _vm.data = $event
                                      },
                                    },
                                    model: {
                                      value: _vm.data.sopMocId,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.data, "sopMocId", $$v)
                                      },
                                      expression: "data.sopMocId",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "col-4" },
                                [
                                  _c("c-text", {
                                    attrs: {
                                      required: _vm.editable && _vm.isRevision,
                                      editable: _vm.editable && _vm.isRevision,
                                      label: "개정사유",
                                      name: "revisionContent",
                                    },
                                    model: {
                                      value: _vm.data.revisionContent,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.data,
                                          "revisionContent",
                                          $$v
                                        )
                                      },
                                      expression: "data.revisionContent",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]),
                          ]
                        ),
                      ]),
                    ],
                    2
                  )
                : _vm._e(),
              _c(
                "c-card",
                {
                  staticClass: "cardClassDetailForm",
                  attrs: { title: "동력기계 상세정보" },
                },
                [
                  _c(
                    "template",
                    { slot: "card-select" },
                    [
                      _vm.popupParam.psiPowerEquipmentId
                        ? _c("c-select", {
                            attrs: {
                              editable: !_vm.isRevision,
                              comboItems: _vm.gridrev.data,
                              type: "custom",
                              typetext: "개정이력",
                              itemText: "revisionNum",
                              itemValue: "psiPowerEquipmentId",
                              name: "selectedPsiPowerEquipmentId",
                              label: "",
                            },
                            on: { input: _vm.rowClickRev },
                            model: {
                              value: _vm.selectedPsiPowerEquipmentId,
                              callback: function ($$v) {
                                _vm.selectedPsiPowerEquipmentId = $$v
                              },
                              expression: "selectedPsiPowerEquipmentId",
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c(
                    "template",
                    { slot: "card-button" },
                    [
                      _c(
                        "q-btn-group",
                        { attrs: { outline: "" } },
                        [
                          _c(
                            "q-btn",
                            {
                              staticClass: "custom-btn",
                              attrs: {
                                icon: "help",
                                color: "deep-purple-6",
                                "text-color": "white",
                                align: "center",
                                round: "",
                              },
                            },
                            [
                              _c(
                                "q-tooltip",
                                {
                                  attrs: {
                                    anchor: "bottom left",
                                    self: "top left",
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "tooltipCustomTop" },
                                    [_vm._v(" (주) ")]
                                  ),
                                  _c("div", { staticClass: "tooltipCustom" }, [
                                    _vm._v(
                                      " ① 방호‧보호장치의 종류에는 법적인 안전/방호장치와 모터보호장치(THT＼R, EOCR, EMPR 등) 등을 기재합니다."
                                    ),
                                    _c("br"),
                                    _vm._v(
                                      " ② 비고에는 인버터 또는 기동방식 등을 기재합니다. "
                                    ),
                                  ]),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c("c-btn", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value:
                                  _vm.editable &&
                                  _vm.popupParam.psiPowerEquipmentId &&
                                  !_vm.isRevision &&
                                  _vm.isEnalbed,
                                expression:
                                  "(editable && popupParam.psiPowerEquipmentId && !isRevision) && isEnalbed",
                              },
                            ],
                            attrs: { label: "개정", icon: "restart_alt" },
                            on: { btnClicked: _vm.SetRevision },
                          }),
                          _c("c-btn", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value:
                                  _vm.editable &&
                                  _vm.popupParam.psiPowerEquipmentId &&
                                  _vm.isRevision &&
                                  _vm.isEnalbed,
                                expression:
                                  "(editable && popupParam.psiPowerEquipmentId && isRevision) && isEnalbed",
                              },
                            ],
                            attrs: { label: "개정취소", icon: "restart_alt" },
                            on: { btnClicked: _vm.CancelRevision },
                          }),
                          _vm.editable &&
                          _vm.popupParam.psiPowerEquipmentId &&
                          _vm.isEnalbed
                            ? _c("c-btn", {
                                attrs: { label: "삭제", icon: "remove" },
                                on: { btnClicked: _vm.deleteData },
                              })
                            : _vm._e(),
                          _vm.editable
                            ? _c("c-btn", {
                                attrs: {
                                  url: _vm.saveUrl,
                                  isSubmit: _vm.isSave,
                                  param: _vm.data,
                                  mappingType: _vm.mappingType,
                                  label: "저장",
                                  icon: "save",
                                },
                                on: {
                                  beforeAction: _vm.saveData,
                                  btnCallback: _vm.saveCallback,
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("template", { slot: "card-detail" }, [
                    _c(
                      "div",
                      { staticClass: "col-3" },
                      [
                        _c("c-text", {
                          attrs: {
                            required: true,
                            editable: _vm.editable,
                            label: "동력기계번호",
                            name: "psiPowerEquipmentNo",
                          },
                          model: {
                            value: _vm.data.psiPowerEquipmentNo,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "psiPowerEquipmentNo", $$v)
                            },
                            expression: "data.psiPowerEquipmentNo",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-3" },
                      [
                        _c("c-text", {
                          attrs: {
                            required: true,
                            editable: _vm.editable,
                            label: "동력기계명",
                            name: "powerMachineName",
                          },
                          model: {
                            value: _vm.data.powerMachineName,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "powerMachineName", $$v)
                            },
                            expression: "data.powerMachineName",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-3" },
                      [
                        _c("c-plant", {
                          attrs: {
                            required: true,
                            editable: _vm.editable,
                            type: "edit",
                            name: "plantCd",
                          },
                          model: {
                            value: _vm.data.plantCd,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "plantCd", $$v)
                            },
                            expression: "data.plantCd",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-3" },
                      [
                        _c("c-equip", {
                          attrs: {
                            editable: _vm.editable,
                            label: "설비",
                            name: "equipmentCd",
                          },
                          model: {
                            value: _vm.data.equipmentCd,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "equipmentCd", $$v)
                            },
                            expression: "data.equipmentCd",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-6" },
                      [
                        _c("c-textarea", {
                          attrs: {
                            editable: _vm.editable,
                            label: "명세",
                            name: "specInfo",
                            rows: 3,
                          },
                          model: {
                            value: _vm.data.specInfo,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "specInfo", $$v)
                            },
                            expression: "data.specInfo",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-6" },
                      [
                        _c("c-textarea", {
                          attrs: {
                            editable: _vm.editable,
                            label: "주요재질",
                            name: "matQuality",
                            rows: 3,
                          },
                          model: {
                            value: _vm.data.matQuality,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "matQuality", $$v)
                            },
                            expression: "data.matQuality",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-6" },
                      [
                        _c("c-textarea", {
                          attrs: {
                            editable: _vm.editable,
                            label: "방호.보호장치의 종류",
                            name: "protectType",
                            rows: 3,
                          },
                          model: {
                            value: _vm.data.protectType,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "protectType", $$v)
                            },
                            expression: "data.protectType",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-6" },
                      [
                        _c("c-textarea", {
                          attrs: {
                            editable: _vm.editable,
                            label: "비고",
                            name: "remarks",
                            rows: 3,
                          },
                          model: {
                            value: _vm.data.remarks,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "remarks", $$v)
                            },
                            expression: "data.remarks",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-6" },
                      [
                        _c("c-text", {
                          attrs: {
                            suffix: "KW",
                            editable: _vm.editable,
                            label: "전동기용량(KW)",
                            name: "motorVol",
                          },
                          model: {
                            value: _vm.data.motorVol,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "motorVol", $$v)
                            },
                            expression: "data.motorVol",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-3" },
                      [
                        _c("c-process", {
                          attrs: {
                            editable: _vm.editable,
                            label: "관련공정",
                            multiple: "single",
                            name: "processCd",
                          },
                          model: {
                            value: _vm.data.processCd,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "processCd", $$v)
                            },
                            expression: "data.processCd",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-3" },
                      [
                        _c("c-dept", {
                          attrs: {
                            editable: _vm.editable,
                            label: "관리부서",
                            name: "deptCd",
                          },
                          model: {
                            value: _vm.data.deptCd,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "deptCd", $$v)
                            },
                            expression: "data.deptCd",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ],
                2
              ),
              _c(
                "div",
                { staticStyle: { "padding-top": "20px !important" } },
                [
                  _c("c-upload", {
                    attrs: {
                      attachInfo: _vm.attachInfo,
                      editable: _vm.editable,
                      label: "첨부파일",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }